import React from 'react'
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Script } from "gatsby"
import { Link } from "gatsby"


import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'



const GbiBridged = () => {
  const { heroImage, logoImage, contentImage1, contentImage2 } = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logoImage: file(relativePath: { eq: "logo-inverted.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        contentImage1: file(relativePath: { eq: "comfort.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        contentImage2: file(relativePath: { eq: "glass.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      
    `
  )
  const image = getImage(heroImage)
  const image2 = getImage(logoImage)
  const image3 = getImage(contentImage1)
  const image4 = getImage(contentImage2)


  // Use like this:
  const bgImage = convertToBgImage(image)


  



  return (
    
    <Layout>
 
    

 
    <main>
      <div id="content" className="subp">
      <section className="row text-center headings">
      <h1 className='h1-heading'>Solfilm</h1>
      <span className="intro-text">
      Tonade rutor ger ökad komfort, större säkerhet och betydligt snyggare utseende. Filmen tar bort 99% av UV-strålarna vilket skyddar bilens interiör mot blekning och missfärgning.<br/>
      Vår solfilm finns i fyra olika toningsgrader från kolsvart solfilm som tar bort all insyn till en nästan genomskinlig film som bara tar bort värme- och uv-strålning. Vi använder endast högkvalitativ och marknadsledande solfilm. Topp fem på världsmarknaden.
      </span> 
      </section>
     <section className="row box">

      <div className="col col-12">
      <GatsbyImage image={image3} alt={"image3"} className="content-image" width="1000"/>
        <div className="inner-content"> 
        <h2>KOMFORT</h2>
       <span>
       Tonade rutor blockerar skadligt ljus och stänger ute solens strålar och värme. Inomhusklimatet blir svalare, såväl i bilen som i ditt uterum. tonade rutor blir inomhusklimatet svalare. Du får skönare bilresor eller ett svalare uterum. Solens strålar reflekteras istället för att släppas igenom och AC:n kan gå på lågvarv. Du får också
insynsskydd, i valfri toningsgrad. Det finns olika varianter, vi guidar dig att välja.</span> 

 
       </div>

      </div>


      </section>
      <section className="row box">

     
      <div className="col col-12">
      <GatsbyImage image={image4} alt={"image4"} className="content-image" width="1000"/>
      <div className="inner-content"> 
        <h2>SÄKERHET</h2>
       <span>
       Tonade rutor ger skydd mot inbrott. En ruta med solfilm är svår att slå sönder. Vi kan också erbjuda securityfilm och stenskottsfilm.
Säkrare mörkerkörning - Du slipper bländas av strålkastare bakifrån.
      </span>
       </div>

      </div>
      </section>
      <section className='text-center'>      <Link className="link" to="/referenser">Klicka här för att se några av våra uppdrag</Link></section>
     <section className="row box">

      <div className="col col-12 text-center">
      <div className="inner-content"> 
      <h2 className="text-center mb-3 wide">

      10 års garanti
      </h2>

      <span>När du anlitar oss kan du räkna med att jobbet blir fint utfört. Du kan kalla oss perfektionister som aldrig ger sig förrän det blir bra. Vi sätter kundens önskemål i fokus och vill alltid att du ska vara nöjd. Därför kan vi erbjuda nöjd kund-garanti och 10 års garanti på allt utfört arbete inom solfilm.</span>
      </div>
      </div>

</section>

     </div>
     </main>
   

  </Layout>


  )
}

export default GbiBridged


